import BigNumber from 'bignumber.js'
import referralABI from 'config/abi/referral.json'
import multicall from 'utils/multicall'
import { getReferralAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchReferral = async (account: string) => {
  const calls = [
    {
      address: getReferralAddress(),
      name: 'referralsCount',
      params: [account],
    },
  ]

  const [referralCount] = await multicall(referralABI, calls)
  return {
    referralCount: new BigNumber(referralCount).toNumber(),
  }
}

export default fetchReferral

/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { createSlice } from '@reduxjs/toolkit'
import fetchBuybackPool from './fetchBuybackPool'
import { fetchBuybackUserAccount, fetchBuybackUserBalance } from './fetchBuybackUser'
import { BuybackState, Buyback } from '../types'

const initialState: BuybackState = {
  data: {
    pool: {
      buybackPrice: 0,
      epochAmount: 0,
      totalAmountToProvide: 0,
      totalAmountProvided: 0,
      currentEpoch: 0,
      timestampNextEpoch: 0,
      totalApplyAmountThisEpoch: 0,
      applyFeeAmount: 0,
    },
    user: {
      allowance: 0,
      tokenBalance: 0,
      waitingAmount: 0,
      sellTokenBalance: 0,
      buybackTokenBalance: 0,
    },
//    pool: {
//      buybackPrice: new BigNumber(0),
//      epochAmount: new BigNumber(0),
//      totalAmountToProvide: new BigNumber(0),
//      totalAmountProvided: new BigNumber(0),
//      currentEpoch: new BigNumber(0),
//      timestampNextEpoch: new BigNumber(0),
//      totalApplyAmountThisEpoch: new BigNumber(0),
//      applyFeeAmount: new BigNumber(0),
//    },
//    user: {
//      allowance: new BigNumber(0),
//      tokenBalance: new BigNumber(0),
//      waitingAmount: new BigNumber(0),
//      sellTokenBalance: new BigNumber(0),
//      buybackTokenBalance: new BigNumber(0),
//    },
  },
}

export const buybackSlice = createSlice({
  name: 'Buyback',
  initialState,
  reducers: {
    setBuybackPoolData: (state, action) => {
      const buybackPoolData = action.payload
      state.data.pool = buybackPoolData
    },
    setBuybackUserData: (state, action) => {
      const buybackUserData = action.payload
      state.data.user = buybackUserData
    },
  },
})

// Actions
export const { setBuybackPoolData, setBuybackUserData } = buybackSlice.actions

// Thunks
export const fetchBuybackPoolDataAsync = () => async (dispatch) => {
  const pool = await fetchBuybackPool()
  dispatch(setBuybackPoolData(pool))
}

export const fetchBuybackUserDataAsync = (account: string) => async (dispatch) => {
  const buybackContractInfo = await fetchBuybackUserAccount(account)
  const tokenContractInfo = await fetchBuybackUserBalance(account)

  const data = {
    tokenBalance: tokenContractInfo.tokenBalance,
    allowance: tokenContractInfo.allowance,
    waitingAmount: buybackContractInfo.waitingAmount,
    sellTokenBalance: buybackContractInfo.sellTokenBalance,
    buybackTokenBalance: buybackContractInfo.buybackTokenBalance,
  }
  dispatch(setBuybackUserData(data))
}

export default buybackSlice.reducer

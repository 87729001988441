import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import buybackABI from 'config/abi/buyback.json'
import multicall from 'utils/multicall'
import { getBuybackAddress, getCakeAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchBuybackUserAccount = async (account: string) => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'waitingAmountTokenToSell',
      params: [account],
    },
    {
      address: getBuybackAddress(),
      name: 'withdrawableTokenToSell',
      params: [account],
    },
    {
      address: getBuybackAddress(),
      name: 'userBuybackedAmount',
      params: [account],
    },
  ]

  const [waitingAmount, sellTokenBalance, buybackTokenBalance] = await multicall(buybackABI, calls)
  return {
    waitingAmount: new BigNumber(waitingAmount).toNumber(),
    sellTokenBalance: new BigNumber(sellTokenBalance).toNumber(),
    buybackTokenBalance: new BigNumber(buybackTokenBalance).toNumber(),
  }
}

export const fetchBuybackUserBalance = async (account: string) => {
  const callsGetSellToken = [
    {
      address: getBuybackAddress(),
      name: 'sellToken',
    },
  ]
  const [sellTokenAddress] = await multicall(buybackABI, callsGetSellToken)
  const calls = [
    {
      address: sellTokenAddress[0],
      name: 'balanceOf',
      params: [account],
    },
    {
      address: sellTokenAddress[0],
      name: 'allowance',
      params: [account, getBuybackAddress()],
    },
  ]

  const [tokenBalance, allowance] = await multicall(erc20ABI, calls)
  return {
    tokenBalance: new BigNumber(tokenBalance).toNumber(),
    allowance: new BigNumber(allowance).toNumber(),
  }
}

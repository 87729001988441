// import contracts from './contracts'
import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
 {
   pid: 25,
   lpSymbol: 'BEAR-BNB',
   lpAddresses: {
     97: '',
     56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
   },
   token: tokens.bear,
   quoteToken: tokens.wbnb,
   plusApr: 0,
 },
 {
   pid: 26,
   lpSymbol: 'BEAR-BUSD',
   lpAddresses: {
     97: '',
     56: '0x9dd10b5d23a05bbac9e9ae0c7ca9890791eabb25',
   },
   token: tokens.bear,
   quoteToken: tokens.busd,
   plusApr: 0,
 },
 {
   pid: 0,
   lpSymbol: 'HONEY-BNB',
   lpAddresses: {
     97: '',
     56: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
   },
   token: tokens.honey,
   quoteToken: tokens.wbnb,
   plusApr: 0,
 },
 {
   pid: 1,
   lpSymbol: 'HONEY-BUSD',
   lpAddresses: {
     97: '',
     56: '0x2961aa26a1cb3068e580099045cc79a5b7b9634c',
   },
   token: tokens.honey,
   quoteToken: tokens.busd,
   plusApr: 0,
 },
   {
     pid: 34,
     lpSymbol: 'WIZARD-BNB',
     lpAddresses: {
       97: '',
       56: '0x791c4b25e5250971d5fe8b0cbe87b836aec7cbf1',
     },
     token: tokens.wizard,
     quoteToken: tokens.wbnb,
     plusApr: 0,
     isNew: true,
   },
   {
     pid: 33,
     lpSymbol: 'LINK-BNB',
     lpAddresses: {
       97: '',
       56: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
     },
     token: tokens.link,
     quoteToken: tokens.wbnb,
     plusApr: 0,
     isNew: true,
   },
 {
   pid: 32,
   lpSymbol: 'NABOX-BUSD',
   lpAddresses: {
     97: '',
     56: '0x29b4abb0f8734ea672a0e82fa47998f710b6a07a',
   },
   token: tokens.nabox,
   quoteToken: tokens.busd,
   plusApr: 0,
   isNew: true,
 },
 {
   pid: 31,
   lpSymbol: 'CAKE-USDT',
   lpAddresses: {
     97: '',
     56: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
   },
   token: tokens.cake,
   quoteToken: tokens.usdt,
   plusApr: 13.47,
 },
 {
   pid: 30,
   lpSymbol: 'CHERRY-BNB',
   lpAddresses: {
     97: '',
     56: '0x3fa0da1e8bc26ccd1e579d0ae59fa409a7e30ddf',
   },
   token: tokens.cherry,
   quoteToken: tokens.wbnb,
   plusApr: 0,
 },
  {
    pid: 29,
    lpSymbol: 'ALPACA-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7752e1fa9f3a2e860856458517008558deb989e3',
    },
    token: tokens.alpaca,
    quoteToken: tokens.busd,
    plusApr: 34.88,
  },
 {
   pid: 28,
   lpSymbol: 'DOT-BNB',
   lpAddresses: {
     97: '',
     56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
   },
   token: tokens.dot,
   quoteToken: tokens.wbnb,
   plusApr: 2.82,
 },
            

//                             {
//                               pid: 30,
//                               lpSymbol: 'MBOX-BNB',
//                               lpAddresses: {
//                                 97: '',
//                                 56: '0x8FA59693458289914dB0097F5F366d771B7a7C3F',
//                               },
//                               token: tokens.mbox,
//                               quoteToken: tokens.wbnb,
//                               plusApr: 5.49,
//                             },
//                             {
//                               pid: 31,
//                               lpSymbol: 'DAI-BUSD',
//                               lpAddresses: {
//                                 97: '',
//                                 56: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
//                               },
//                               token: tokens.dai,
//                               quoteToken: tokens.busd,
//                               plusApr: 0.88,
//                             },
 {
   pid: 2,
   lpSymbol: 'CAKE-BNB',
   lpAddresses: {
     97: '',
     56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
   },
   token: tokens.cake,
   quoteToken: tokens.wbnb,
   plusApr: 2.64,
 },
 {
   pid: 3,
   lpSymbol: 'BNB-BUSD',
   lpAddresses: {
     97: '',
     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
   },
   token: tokens.wbnb,
   quoteToken: tokens.busd,
   plusApr: 9.43,
 },
 {
   pid: 4,
   lpSymbol: 'USDT-BUSD',
   lpAddresses: {
     97: '',
     56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
   },
   token: tokens.usdt,
   quoteToken: tokens.busd,
   plusApr: 3.09,
 },
 {
   pid: 5,
   lpSymbol: 'USDT-BNB',
   lpAddresses: {
     97: '',
     56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
   },
   token: tokens.usdt,
   quoteToken: tokens.wbnb,
   plusApr: 14.65,
 },
 {
   pid: 6,
   lpSymbol: 'USDC-BUSD',
   lpAddresses: {
     97: '',
     56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
   },
   token: tokens.usdc,
   quoteToken: tokens.busd,
   plusApr: 1.94,
 },
 {
   pid: 7,
   lpSymbol: 'BTCB-ETH',
   lpAddresses: {
     97: '',
     56: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
   },
   token: tokens.btcb,
   quoteToken: tokens.eth,
   plusApr: 1.91,
 },
 {
   pid: 24,
   lpSymbol: 'BTCB-BUSD',
   lpAddresses: {
     97: '',
     56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
   },
   token: tokens.btcb,
   quoteToken: tokens.busd,
   plusApr: 7.22,
 },
 {
   pid: 9,
   lpSymbol: 'ETH-BNB',
   lpAddresses: {
     97: '',
     56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
   },
   token: tokens.eth,
   quoteToken: tokens.wbnb,
   plusApr: 5.11,
 },
 {
   pid: 10,
   lpSymbol: 'BTCB-BNB',
   lpAddresses: {
     97: '',
     56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
   },
   token: tokens.btcb,
   quoteToken: tokens.wbnb,
   plusApr: 2.77,
 },
 {
   pid: 11,
   lpSymbol: 'BELT-BNB',
   lpAddresses: {
     97: '',
     56: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
   },
   token: tokens.belt,
   quoteToken: tokens.wbnb,
   plusApr: 0.82,
 },
 {
   pid: 12,
   lpSymbol: 'CAKE-BUSD',
   lpAddresses: {
     97: '',
     56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
   },
   token: tokens.cake,
   quoteToken: tokens.busd,
   plusApr: 22.1,
 },
 {
   pid: 13,
   lpSymbol: 'RINI-BNB',
   lpAddresses: {
     97: '',
     56: '0x5F188bEDe05D11b8d1474D6832b41d3EaE4ED98E',
   },
   token: tokens.rini,
   quoteToken: tokens.wbnb,
   plusApr: 3.29,
 },
 {
   pid: 14,
   lpSymbol: 'TUSD-BUSD',
   lpAddresses: {
     97: '',
     56: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
   },
   token: tokens.tusd,
   quoteToken: tokens.busd,
   plusApr: 0.76,
 },
 {
   pid: 15,
   lpSymbol: 'ETH-USDC',
   lpAddresses: {
     97: '',
     56: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
   },
   token: tokens.eth,
   quoteToken: tokens.usdc,
   plusApr: 6.82,
 },
 {
   pid: 16,
   lpSymbol: 'USDC-USDT',
   lpAddresses: {
     97: '',
     56: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
   },
   token: tokens.usdc,
   quoteToken: tokens.usdt,
   plusApr: 1.05,
 },
 {
   pid: 17,
   lpSymbol: 'BEAR',
   lpAddresses: {
     97: '',
     56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',  // fixed me to BEAR-BNB!
   },
   token: tokens.bear,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   isTokenOnly: true,
   isBoosted: true,
 },
 {
   pid: 18,
   lpSymbol: 'HONEY',
   lpAddresses: {
     97: '',
     56: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
   },
   token: tokens.honey,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   isTokenOnly: true,
   isBoosted: true,
 },
 {
   pid: 19,
   lpSymbol: 'WBNB',
   lpAddresses: {
     97: '',
     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
   },
   token: tokens.wbnb,
   quoteToken: tokens.busd,
   plusApr: 0,
   isTokenOnly: true,
 },
 {
   pid: 20,
   lpSymbol: 'CAKE',
   lpAddresses: {
     97: '',
     56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
   },
   token: tokens.cake,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   isTokenOnly: true,
 },
 {
   pid: 21,
   lpSymbol: 'BTCB',
   lpAddresses: {
     97: '',
     56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
   },
   token: tokens.btcb,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   isTokenOnly: true,
 },
 {
   pid: 22,
   lpSymbol: 'ETH',
   lpAddresses: {
     97: '',
     56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
   },
   token: tokens.eth,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   isTokenOnly: true,
 },
                             
 // Inactive (for RoyalJelly)
// {
//   pid: 27,
//   lpSymbol: 'TENGU-BNB',
//   lpAddresses: {
//     97: '',
//     56: '0x4eecd37575009b71fc09a959207391e5c6c7544b',
//   },
//   token: tokens.tengu,
//   quoteToken: tokens.wbnb,
//   plusApr: 0,
// },
                             
                             
                             
//   {
//     pid: 24,
//     lpSymbol: 'HONEY-USDT',
//     lpAddresses: {
//       97: '',
//       56: '0x6498b718f6519747d553617f3aacada066aF06ba',
//     },
//     token: tokens.honey,
//     quoteToken: tokens.usdt,
//     plusApr: 0,
//   },
//   {
//     pid: 27,
//     lpSymbol: 'Cherry-BNB',
//     lpAddresses: {
//       97: '',
//       56: '0x3fa0da1e8bc26ccd1e579d0ae59fa409a7e30ddf',
//     },
//     token: tokens.cherry,
//     quoteToken: tokens.wbnb,
//     plusApr: 0,
//   },
// {
//   pid: 25,
//   lpSymbol: 'RINI',
//   lpAddresses: {
//     97: '',
//     56: '0x5F188bEDe05D11b8d1474D6832b41d3EaE4ED98E',
//   },
//   token: tokens.rini,
//   quoteToken: tokens.wbnb,
//   plusApr: 0,
//   isTokenOnly: true,
// },
                             
                             
/*
  {
    pid: 10,
    lpSymbol: 'CUB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x0EF564D4F8D6C0ffE13348A32e21EFd55e508e84',
    },
    token: tokens.cub,
    quoteToken: tokens.busd,
  },
  {
    pid: 11,
    lpSymbol: 'CUB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xc08C74dC9EF46C6dB122b30c48a659831017DD2E',
    },
    token: tokens.cub,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 9,
    lpSymbol: 'bLEO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x243E060DEcA0499fCaE6ABe548C0115faaBa0ed4',
    },
    token: tokens.bleo,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 6,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },
  {
    pid: 8,
    lpSymbol: 'BTCB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
  },
  // This farm is here for BAKE price for kingdom vault pid 5
  {
    pid: 5,
    lpSymbol: 'BAKE-BNB LP',
    lpAddresses: {
      97: '0xE66790075ad839978fEBa15D4d8bB2b415556a1D',
      56: '0x3Da30727ed0626b78C212e81B37B97A8eF8A25bB',
    },
    token: tokens.bake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 7,
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'DAI-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
  },
  {
    pid: 23,
    lpSymbol: 'DEC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x4c79edab89848f34084283bb1fe8eac2dca649c3',
    },
    token: tokens.dec,
    quoteToken: tokens.busd,
  },
  // {
  //   pid: 4,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680Dd100E4b394Bda26A59dD5c119A391e747d18',
  //   },
  //   token: tokens.usdc,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'DOT-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbCD62661A6b1DEd703585d3aF7d7649Ef4dcDB5c',
  //   },
  //   token: tokens.dot,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'CAKE-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0Ed8E0A2D99643e1e65CCA22Ed4424090B8B7458',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'CAKE-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },

  // {
  //   pid: 13,
  //   isTokenOnly: true,
  //   lpSymbol: 'bLEO',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x243E060DEcA0499fCaE6ABe548C0115faaBa0ed4', // bLEO-WBNB LP
  //   },
  //   tokenSymbol: 'bLEO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x6421531AF54C7B14Ea805719035EBf1e3661c44A',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },

  // {
  //   pid: 14,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
  //   },
  //   token: tokens.busd,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 15,
  //   isTokenOnly: true,
  //   lpSymbol: 'WBNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
  //   },
  //   token: tokens.wbnb,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 16,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd', // USDT-BUSD LP
  //   },
  //   token: tokens.usdt,
  //   quoteToken: tokens.busd,
  // },
  {
    pid: 12,
    isTokenOnly: true,
    lpSymbol: 'CUB',
    lpAddresses: {
      97: '',
      56: '0x0EF564D4F8D6C0ffE13348A32e21EFd55e508e84', // CUB-BUSD LP
    },
    token: tokens.cub,
    quoteToken: tokens.busd,
  },
  {
    pid: 17,
    isTokenOnly: true,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0xb8875e207ee8096a929d543c9981c9586992eacb', // BTCB-BUSD LP
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
  },
  {
    pid: 18,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0xd9a0d1f5e02de2403f68bb71a15f8847a854b494', // ETH-BUSD LP
    },
    token: tokens.eth,
    quoteToken: tokens.busd,
  },
  {
    pid: 21,
    isTokenOnly: true,
    lpSymbol: 'DOT',
    lpAddresses: {
      97: '',
      56: '0x54c1ec2f543966953f2f7564692606ea7d5a184e', // DOT-BUSD LP
    },
    token: tokens.dot,
    quoteToken: tokens.busd,
  },
  // {
  //   pid: 19,
  //   isTokenOnly: true,
  //   lpSymbol: 'DAI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc', // DAI-BUSD LP
  //   },
  //   token: tokens.dai,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 20,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDC',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18', // USDC-BUSD LP
  //   },
  //   token: tokens.usdc,
  //   quoteToken: tokens.busd,
  // },

  // {
  //   pid: 22,
  //   isTokenOnly: true,
  //   lpSymbol: 'CAKE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.busd,
  // },

  // KINGDOMS
  {
    pid: 4,
    altPid: 12,
    compounding: 720,
    isKingdom: true,
    isKingdomToken: true,
    farmType: 'Cub DeFi',
    lpSymbol: 'CUB',
    lpAddresses: {
      97: '',
      56: '0x0EF564D4F8D6C0ffE13348A32e21EFd55e508e84', // CUB-BUSD LP
    },
    token: tokens.cub,
    quoteToken: tokens.busd,
    kingdomContract: '0xc2adf5fc4d4e6c2cc97f8190acbdf808c689117c',
  },
  {
    pid: 0,
    altPid: 0,
    compounding: 4200,
    isKingdom: true,
    isKingdomToken: true,
    farmType: 'Pancake v2',
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
    kingdomContract: '0x77440f4dc7b4ef591e78d460374bd12d3d6bdad8',
  },
  {
    pid: 1,
    altPid: 252,
    compounding: 365,
    isKingdom: true,
    farmType: 'Pancake v2',
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    kingdomContract: '0x701d4f8168b00abbd948d36e11added4e1cac742',
  },
  {
    pid: 2,
    altPid: 261,
    compounding: 365,
    isKingdom: true,
    farmType: 'Pancake v2',
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x74e4716e431f45807dcf19f284c7aa99f18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    kingdomContract: '0x3582933accc5732484138a2dd61fcdd02d0a021c',
  },
  {
    pid: 3,
    altPid: 255,
    compounding: 365,
    isKingdom: true,
    farmType: 'Pancake v2',
    lpSymbol: 'DOT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    },
    token: tokens.dot,
    quoteToken: tokens.wbnb,
    kingdomContract: '0x03e48360dc132a1838492b6870c98d2bd895ea9a',
  },
  {
    pid: 5,
    compounding: 365,
    isKingdom: true,
    farmType: 'Bakery',
    lpSymbol: 'BTC-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58521373474810915b02FE968D1BCBe35Fc61E09',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
    kingdomContract: '0xbdc40a031f6908a8203fb1c75bb2b9c4abf59e2e',
  },
 */
]

export default farms

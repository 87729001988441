import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import vaultABI from 'config/abi/vault.json'
import multicall from 'utils/multicall'
import vaultsConfig from 'config/constants/vaults'
import { getMasterChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchVaultUserAllowances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = vault.isTokenOnly ? vault.tokenAddresses[CHAIN_ID] : vault.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, vault.vaultAddresses[CHAIN_ID]] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = vault.isTokenOnly ? vault.tokenAddresses[CHAIN_ID] : vault.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchVaultUserStakedBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    return {
      address: vault.vaultAddresses[CHAIN_ID],
      name: 'balanceAmountOf',
      params: [account],
    }
  })

  const rawStakedBalances = await multicall(vaultABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import vaultABI from 'config/abi/vault.json'
import strategyABI from 'config/abi/vaultStrategy.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import vaultsConfig from 'config/constants/vaults'
import QuoteToken from '../../config/constants/tokens'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchVaults = async () => {
  const data = await Promise.all(
    vaultsConfig.map(async (vaultConfig) => {
      const lpAdress = vaultConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: vaultConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: vaultConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        //        {
        //          address: vaultConfig.isTokenOnly ? vaultConfig.tokenAddresses[CHAIN_ID] : lpAdress,
        //          name: 'balanceOf',
        //          params: [getMasterChefAddress()],
        //        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: vaultConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: vaultConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        //        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls)

      const [[strategyAddr], totalWantAmount, totalShare] = await multicall(vaultABI, [
        {
          address: vaultConfig.vaultAddresses[CHAIN_ID],
          name: 'strategy',
        },
        {
          address: vaultConfig.vaultAddresses[CHAIN_ID],
          name: 'balance',
        },
        {
          address: vaultConfig.vaultAddresses[CHAIN_ID],
          name: 'totalSupply',
        },
      ])
      //    console.log('strategyAddr', strategyAddr)

      const [
        masterchefAddr,
        pid,
        earnPerBlock,
        info,
        totalAllocPoint,
        withdrawalFee,
        withdrawalFeeMax,
        lpTokenBalanceMC,
        lpTokenStakedBalance,
      ] = await multicall(strategyABI, [
        {
          address: strategyAddr,
          name: 'masterchef',
        },
        {
          address: strategyAddr,
          name: 'poolId',
        },
        {
          address: strategyAddr,
          name: 'targetPerBlock',
        },
        {
          address: strategyAddr,
          name: 'targetPoolInfo',
        },
        {
          address: strategyAddr,
          name: 'targetTotalAllocPoint',
        },
        {
          address: strategyAddr,
          name: 'withdrawalFee',
        },
        {
          address: strategyAddr,
          name: 'FEE_MAX',
        },
        {
          address: strategyAddr,
          name: 'totalBalanceOfPool',
        },
        {
          address: strategyAddr,
          name: 'balanceOfPool',
        },
      ])

      const allocPoint = new BigNumber(info[1]._hex) // info.allocPoint
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      let tokenAmount
      let tokenAmountVault
      let lpTotalInQuoteToken
      let lpVaultInQuoteToken
      let tokenPriceVsQuote
      if (vaultConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
        tokenAmountVault = new BigNumber(lpTokenStakedBalance).div(new BigNumber(10).pow(tokenDecimals))
        if (vaultConfig.tokenSymbol === QuoteToken.busd.symbol && vaultConfig.quoteTokenSymbol === QuoteToken.busd.symbol) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
        lpVaultInQuoteToken = tokenAmountVault.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenTargetRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
        const lpTokenVaultRatio = new BigNumber(lpTokenStakedBalance).div(new BigNumber(lpTotalSupply))

        // Total value in total staking at target in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenTargetRatio)

        // Total value in total staking at vault in quote token value
        lpVaultInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenVaultRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTotalInQuoteToken)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTotalInQuoteToken)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmountVault = new BigNumber(tokenBalanceLP)
          .div(new BigNumber(10).pow(tokenDecimals))
          .times(lpTokenVaultRatio)
        const quoteTokenAmountVault = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenVaultRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
      }

      return {
        ...vaultConfig,
        tokenAmount: tokenAmount.toJSON(),
        tokenAmountVault: tokenAmountVault.toJSON(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        lpVaultInQuoteToken: lpVaultInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        depositFeeBP: 0,
        withdrawFeeBP: withdrawalFee / withdrawalFeeMax,
        earnPerBlock: new BigNumber(earnPerBlock).toNumber(),
        amountPerShare: totalWantAmount / totalShare,
      }
    }),
  )
  return data
}

export default fetchVaults

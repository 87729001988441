import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import buybackABI from 'config/abi/buyback.json'
import multicall from 'utils/multicall'
import { getBuybackAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchPool = async () => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'buybackPrice',
    },
    {
      address: getBuybackAddress(),
      name: 'epochBuybackAmount',
    },
    {
      address: getBuybackAddress(),
      name: 'totalBuybackAmountToProvide',
    },
    {
      address: getBuybackAddress(),
      name: 'totalBuybackAmountProvided',
    },
    {
      address: getBuybackAddress(),
      name: 'currentEpoch',
    },
    {
      address: getBuybackAddress(),
      name: 'nextEpochTimestamp',
    },
    {
      address: getBuybackAddress(),
      name: 'totalApplyAmountThisEpoch',
    },
    {
      address: getBuybackAddress(),
      name: 'applyFeeAmount',
    },
  ]

  const [
    buybackPrice,
    epochAmount,
    totalAmountToProvide,
    totalAmountProvided,
    currentEpoch,
    timestampNextEpoch,
    totalApplyAmountThisEpoch,
    applyFeeAmount,
  ] = await multicall(buybackABI, calls)
  /*
  return {
    buybackPrice: buybackPrice[0],
    epochAmount: epochAmount[0],
    totalAmountToProvide: totalAmountToProvide[0],
    totalAmountProvided: totalAmountProvided[0],
    currentEpoch: currentEpoch[0],
    timestampNextEpoch: timestampNextEpoch[0],
    totalApplyAmountThisEpoch: totalApplyAmountThisEpoch[0],
    applyFeeAmount: applyFeeAmount[0],
  }
   */
  return {
    buybackPrice: new BigNumber(buybackPrice).toNumber(),
    epochAmount: new BigNumber(epochAmount).toNumber(),
    totalAmountToProvide: new BigNumber(totalAmountToProvide).toNumber(),
    totalAmountProvided: new BigNumber(totalAmountProvided).toNumber(),
    currentEpoch: new BigNumber(currentEpoch).toNumber(),
    timestampNextEpoch: new BigNumber(timestampNextEpoch).toNumber(),
    totalApplyAmountThisEpoch: new BigNumber(totalApplyAmountThisEpoch).toNumber(),
    applyFeeAmount: new BigNumber(applyFeeAmount).toNumber(),
  }
}

export default fetchPool
